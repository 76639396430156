<template>
  <VDialog
    @click:outside="showConfirmDialog"
    v-model="visibility"
    :persistent="isChanged"
    max-width="600px"
     content-class="cross__dialog"
     scrollable
     :transition="$dialogTransition"
  >
    <VCard :height="$vuetify.breakpoint.smAndDown ? '500' : ''">
          <div class="cross__inner">
      <VBtn fab small  elevation="0" @click="showConfirmDialog">
        <VIcon>mdi-close</VIcon>
      </VBtn>
      </div>
      <VCardTitle>
        <span class="font-20" :class="{ 'text-body-1': $vuetify.breakpoint.xs }">
            {{$t('form.adding')}} {{ addName }}
        </span>
      </VCardTitle>
      <VDivider />
      <VCardText>
        <div class="text-center" v-if="loading">
          <VProgressCircular
            :size="50"
            color="primary"
            indeterminate
          ></VProgressCircular>
        </div>
        <VContainer v-else>
          <VRow>
            <VCol cols="12" :class="$vuetify.breakpoint.smAndDown ? 'text-center' : 'flex'">
              <VRow v-if="!photoPreview && !$vuetify.breakpoint.smAndDown">
                <VCol cols="3">
                  <img
                    src="@/assets/images/account-photo.svg"
                    alt="account-photo.svg"
                  />
                </VCol>
                <VCol cols="6" class="align-self-center">
                  <label for="user_photo">
                    <VBtn :style="`background: ${$vuetify.theme.dark ? '#545252' : '#fafafa'} border: ${$vuetify.theme.dark ? '#fafafa' : '#1b1e21 1px solid'}`" @click="getFile">
                      {{ $t("profile.changeBtn") }}
                    </VBtn>
                  </label>
                </VCol>
              </VRow>
              <VRow v-if="!photoPreview && $vuetify.breakpoint.smAndDown">
                <VCol cols="12">
                  <img
                    src="@/assets/images/account-photo.svg"
                    alt="account-photo.svg"
                  />
                </VCol>
                <VCol cols="12" class="align-self-center">
                  <label for="user_photo">
                    <VBtn :style="`background: ${$vuetify.theme.dark ? '#545252' : '#fafafa'} border: ${$vuetify.theme.dark ? '#fafafa' : '#1b1e21 1px solid'}`" @click="getFile">
                      {{ $t("profile.changeBtn") }}
                    </VBtn>
                  </label>
                </VCol>
              </VRow>
              <VFileInput
                id="user_photo"
                class="d-none"
                v-model="user.image"
                accept="image/*"
                @change="makePreview"
              />
              <VRow v-if="photoPreview && !$vuetify.breakpoint.smAndDown" align="center">
                <VCol cols="3">
                  <img :src="photoPreview" alt="" width="80" class="img-preview"/>
                </VCol>
                <VCol cols="6">
                  <VBtn :style="`background: ${$vuetify.theme.dark ? '#545252' : '#fafafa'} border: ${$vuetify.theme.dark ? '#fafafa' : '#1b1e21 1px solid'}`" @click="onChangeImage" depressed>
                    {{ $t("btn.delete") }}
                  </VBtn>
                </VCol>
              </VRow>
              <VRow v-if="photoPreview && $vuetify.breakpoint.smAndDown" align="center">
                <VCol cols="12">
                  <img :src="photoPreview" alt="" width="80" class="img-preview"/>
                </VCol>
                <VCol cols="12">
                  <VBtn :style="`background: ${$vuetify.theme.dark ? '#545252' : '#fafafa'} border: ${$vuetify.theme.dark ? '#fafafa' : '#1b1e21 1px solid'}`" @click="onChangeImage" depressed>
                    {{ $t("btn.delete") }}
                  </VBtn>
                </VCol>
              </VRow>
            </VCol>
            <VRow class="align-start mt-3 justify-start">
              <VCol :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" class="py-0">
                <VTextField
                  :label="$t('form.name')"
                  dense
                  color="green"
                  outlined
                  v-model="user.name"
                  :error-messages="nameErrors"
                  :hint="onEngText ? '' : $t('form.hint.NameNoEngText')"
                  v-on:keypress="validationForName($event)"
                  @paste="validationForName($event)"
                />
              </VCol>
              <VCol :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" class="py-0">
                <VTextField
                  :label="$t('form.surname')"
                  dense
                  outlined
                  color="green"
                  v-model="user.surname"
                  :error-messages="surnameErrors"
                  :hint="onEngText ? '' :  $t('form.hint.SurnameNoEngText')"
                  v-on:keypress="validationForSurname($event)"
                  @paste="validationForSurname($event)"
                />
              </VCol>
              <VCol :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" class="py-0">
                <VTextField
                  class="inputPhone"
                  :label="$t('form.phone_number')"
                  dense
                  color="green"
                  prefix="+38"
                  :maxLength="20"
                  outlined
                  v-model="user.phone"
                  :error-messages="phoneErrors"
                  autocomplete="new-password"
                  v-on:keypress="validationForPhoneNumber($event)"
                  @paste="validationForPhoneNumber($event)"
                >
                </VTextField>
              </VCol>
              <VCol :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" class="py-0">
                <VTextField
                  :label="$t('form.email')"
                  dense
                  outlined
                  v-model="user.email"
                  autocomplete="off"
                  :error-messages="emailErrors"
                  color="green"
                  v-on:keypress="validationForEmail($event)"
                  @paste="validationForEmail($event)"
                />
              </VCol>
              <VCol :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" class="py-0">
                <v-autocomplete
                  :items="depots"
                  :item-value="'uuid'"
                  :item-text="'name'"
                  outlined
                  :label="$t('form.storage')"
                  :no-data-text="$t('table.noData')"
                  dense
                  v-model="user.deport_uuid"
                  :disabled="!permissions.can_delete_manager"
                  :error-messages="uuidErrors"
                  color="success"
                  item-color="success"
                  v-on:keypress="validationForWarehouses($event)"
                  @paste="validationForWarehouses($event)"
                />
              </VCol>
            </VRow>
            <VRow class="align-center mt-3 justify-center text-left">
              <VCol cols="12" class="d-block font-14 text-left font-weight-medium"
                    :style="`color: ${$vuetify.theme.dark ? '#fafafa' : '#545252'}`">
                {{$t('form.password')}}
              </VCol>
              <VCol cols="12" class="pb-5 pt-0">
                <VTextField
                  outlined
                  :label="$t('form.password')"
                  dense
                  v-model="user.password"
                  :type="passwordVisible ? 'text' : 'password'"
                  :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="passwordVisible = !passwordVisible"
                  :error-messages="passwordErrors"
                  autocomplete="new-password"
                  hide-details="auto"
                  color="success"
                  v-on:keypress="validationForPassword($event)"
                  @paste="validationForPassword($event)"
                />
              </VCol>
              <VCol cols="12" class="py-0">
                <VTextField
                  outlined
                  :label="$t('profile.сonfirm_pass')"
                  dense
                  v-model="user.passwordRepeat"
                  :type="repeatPasswordVisible ? 'text' : 'password'"
                  :append-icon="repeatPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="repeatPasswordVisible = !repeatPasswordVisible"
                  :error-messages="passwordRepeatErrors"
                  hide-details="auto"
                  color="success"
                  v-on:keypress="validationForPassword($event)"
                  @paste="validationForPassword($event)"
                />
              </VCol>
            </VRow>
          </VRow>
        </VContainer>
      </VCardText>
      <VCardActions>
        <VRow class="my-0 py-0">
          <VCol cols="6">
            <VBtn
              @click="onClose"
              block
              class="text-white text-transform-none br-10 w-100"
              dark
             :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t('btn.cancel') }}
            </VBtn>
          </VCol>
          <VCol cols="6">
            <VBtn
              block
              class="success-bg text-white text-transform-none br-10 w-100"
              @click="onCreate"
              :disabled="!isChanged"
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"

            >
             {{ $t('btn.create') }}
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
    <ConfirmLeftDialog
    :visible="visibleConfirm"
    @close="visibleConfirm = false"
    @delete="onClose"
    />
  </VDialog>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {
  required, email, requiredIf, sameAs
} from 'vuelidate/lib/validators';
import ConfirmLeftDialog from '@/components/dialog/ConfirmLeftDialog.vue';
import language from '../../mixins/language';
import notifications from '../../mixins/notifications';
import usersService from '../../services/request/users/usersService';
import EventBus from '../../events/EventBus';
import passwordMask from '../../validators/passwordMask';
import depotService from '../../services/request/depot/depotService';
import user from '../../mixins/user';
import phoneMask from '../../validators/phoneMask';
import {
  validationForName,
  validationForSurname,
  validationForPhoneNumber,
  validationForEmail,
  validationForWarehouses, validationForPassword
} from "../../mixins/helpers";
import noEngTextValidator from "@/validators/noEngText";
import fileSizeValidation from "@/validators/fileSizeAvatar";

export default {
  name: 'AddUserDialog',
  mixins: [language, validationMixin, notifications, user],
  validations: {
    user: {
      name: { required, noEngTextValidator},
      surname: { required, noEngTextValidator},
      deport_uuid: {
        // eslint-disable-next-line func-names
        required: requiredIf(function () {
          return this.accessRole;
        }),
      },
      email: { required, email },
      password: { required, passwordMask },
      passwordRepeat: { required, passwordMask, sameAsPassword: sameAs("password") },
      phone: { required, phoneMask },
      image: { fileSizeValidation },
    },
  },
  data: () => ({
    onEngText:true,
    user: {
      name: '',
      surname: '',
      email: '',
      password: '',
      passwordRepeat: '',
      phone: '',
      image: null,
    },
    visibleConfirm:false,
    depots: [],
    accessRole: false,
    passwordVisible: false,
    repeatPasswordVisible: false,
    loading: false,
    isChanged:false,
    photoPreview: null,
    resetPhoto: false
  }),
  async mounted() {
    // orderDialog('role', this.role);
    this.updateRoleInfo();
    this.user.deport_uuid = this.getUser.uuid_deport;
    if(this.permissions.can_edit_client) {
      try {
        this.depots = await depotService.getDepotsList();
      } catch (e) {
        console.log(e)
      }
    }
  },
  watch: {
    role: 'updateRoleInfo',
        user: {
      deep: true,
      handler() {
        const values = Object.values(this.user);
             if(values.filter((item) => item).length){
          this.isChanged = true
        }else{
           this.isChanged = false
        }
      },
    },
    resetPhoto: {
      deep: true,
      handler() {
        this.onResetImage()
      }
    }
  },
  methods: {
    validationForPassword,
    validationForWarehouses,
    validationForEmail,
    validationForPhoneNumber,
    validationForSurname,
    validationForName,
    onResetImage(){
      this.photoPreview = false
    },
    onChangeImage() {
      delete this.user.image
      this.photoPreview = null;
    },
    getFile() {
      const fileUpload = document.getElementById("user_photo");
      if (fileUpload != null) {
        fileUpload.click();
      }
    },
    makePreview() {
      if(this.$v.user.image.fileSizeValidation){
      let photoPreview = null;
      const { image } = this.user;
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = ev => {
        photoPreview = ev.target.result;
        this.photoPreview = photoPreview;
      };
    } else {
        this.user.image = null;
        this.photoPreview = null;
        this.setErrorNotification(this.$t("form.errors.photoMaxSizeAvatar"));
      }
    },
    showConfirmDialog(){
      if(this.isChanged){
      this.visibleConfirm = true
      }else{
        this.onClose();
      }
    },
    updateRoleInfo() {
      if (this.role === 'employee' || this.role === 'client' || this.role === 'manager') {
        if (this.permissions.can_edit_client) {
          // orderDialog(this.permissions.can_create_user_deport);
          this.accessRole = true;
          // this.validations.user.deport_uuid.required = true;
        }
      }
    },
    onClose() {
      this.user = [];
      delete this.user.image
      this.photoPreview = null;
      this.$v.$reset();
      this.visibility = false;
    },
    async onCreate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          const formData = new FormData();
          formData.append('name', this.user.name);
          formData.append('surname', this.user.surname);
          formData.append('email', this.user.email);
          if (this.accessRole) {
            formData.append('uuid_deport', this.user.deport_uuid);
          }
          formData.append('password', this.user.password);
          formData.append('phone', `+38${this.user.phone}`);
          formData.append('type', this.role);
          if (this.user.image) {
            formData.append('image', this.user.image);
          }
          await usersService.addUser(formData);
          this.setSuccessNotification(this.$t('users.user_add_success'));
          this.loading = false;
          this.clearUser();
          this.$v.$reset();
          EventBus.$emit('added-user');
          this.resetPhoto = !this.resetPhoto
          this.visibility = false;
        } catch (e) {
          this.loading = false;
          this.setErrorNotification(e.error.description)
          console.log(e)
        }
      }
    },
    clearUser() {
      Object.keys(this.user).forEach((key) => {
        this.user[key] = null;
      });
    },
  },
  components:{
    ConfirmLeftDialog
  },
  props: {
    addName: String,
    visible: {
      required: true,
    },
    role: {
      required: true,
      type: String,
    },
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit('close');
      },
    },
    uuidErrors() {
      const errors = [];
      if (!this.$v.user.deport_uuid.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.deport_uuid.required
      && errors.push(this.$t('form.errors.StorageRequired'));
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.user.name.$dirty) {
        return errors;
      }
      if (!this.$v.user.name.noEngTextValidator) {
        errors.push(this.$t("form.hint.NameNoEngText"));
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.name.required
      && errors.push(this.$t('form.errors.NameRequired'));
      return errors;
    },
    surnameErrors() {
      const errors = [];
      if (!this.$v.user.surname.$dirty) {
        return errors;
      }
      if (!this.$v.user.surname.noEngTextValidator) {
        errors.push(this.$t("form.hint.SurnameNoEngText"));
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.surname.required
      &&  errors.push(this.$t('form.errors.SurnameRequired'));
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.user.phone.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.phone.required
      && errors.push(this.$t('form.errors.PhoneNumberRequired'));
      // eslint-disable-next-line no-unused-expressions
      if(this.user.phone){
        !this.$v.user.phone.phoneMask
        && errors.push(this.$t('form.errors.PhoneNumberMustBeLike'));
      }
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.user.email.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.email.required
      &&  errors.push(this.$t('form.errors.EmailRequired'));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.email.email
      &&  errors.push(this.$t('form.errors.EmailMustBeValid'));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.user.password.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.password.required
      &&  errors.push(this.$t('form.errors.PasswordRequired'));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.password.passwordMask
      && errors.push(this.$t('form.errors.PasswordMustBeLike'));
      return errors;
    },
    passwordRepeatErrors() {
      const errors = [];
      if (!this.$v.user.passwordRepeat.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      if (!this.$v.user.passwordRepeat.required) {
        errors.push(this.$t("form.errors.ConfirmPassRequired"));

        return errors;
      }
      if (!this.$v.user.passwordRepeat.passwordMask) {
        errors.push(this.$t("form.errors.PasswordMustBeLike"));

        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.passwordRepeat.sameAsPassword &&
      errors.push(this.$t("form.errors.PasswordsDontMatch"));
      return errors;
    },
  },
};
</script>

<style scoped>
.img-preview {
  border-radius: 15%;
  border: 1px solid lightgray;
  min-width: 80px;
  width: 80px;
  height: 80px;
  object-fit: cover;
}
</style>
