<template>
  <tr>
    <td class="text-center" v-if="canEdit">
      <VBtn
        color="primary"
        small
        @click="onEditUser(user.uuid)"
        class="btn-edit white--text"
        :class="{ sm: !isLarge }"
      >
        <VIcon dense>
          mdi-pencil
        </VIcon>
      </VBtn>
      <!-- <VIcon color="#BE0000" v-if="canDelete" @click="deleteUserDialog = true">
        mdi-delete
      </VIcon> -->
    </td>
    <td class="text-center d-flex align-center justify-content-center">
      <img
        :src="user.path_img"
        alt=""
        class="image"
        :class="{ sm: !isLarge }"
        v-if="user.path_img"
      />
      <VIcon :size="isLarge ? '36' : '31'" color="#CAC8C8" v-else>mdi-account-circle</VIcon>
    </td>
    <td class="mw-sm">{{ user.name }}</td>
    <td class="mw-sm">{{ user.surname }}</td>
    <td class="mw-sm">{{ user.email }}</td>
    <td>{{ user.phone | VMask("+38 (###) ##-##-###") }}</td>
    <td v-if="$route.name === 'clients'" class="text-center">{{ user.deliveries_balance }}</td>
    <td v-if="$route.name === 'clients'" class="text-center">{{ user.deliveries_negative_limit }}</td>
    <td class="text-center">{{ user.time_created | createdAt(user.time_created, currentLanguage) }}</td>
    <td class="text-center">
       <div class="d-flex align-center justify-content-center">
          <StatusCircle
            :status-name="user.id_state"
            :type="'supply'"
            class="pb-1"/>
              <div class="elispsis">
                {{ $t('users.state_' + user.id_state) }}
              </div>
         </div>
    </td>

    <ConfirmDeleteDialog
      v-if="canDelete"
      :visible="deleteUserDialog"
      :uuid="user.uuid"
      @close="deleteUserDialog = false"
      @delete="onDeleteUser"
    />
    <EditClientDialog
      :user-item="user"
      :visible="editUserDialog"
      v-if="($route.name === 'clients'|| $route.name === 'crm-users') && editUserDialog && canEdit"
      @close="editUserDialog = false"
      @resetFilter="$emit('resetFilter', true)"
      :type="type"
    />
    <EditUserDialog
      v-if="(editUserDialog && canEdit && $route.name !== 'clients' && $route.name !== 'crm-users')"
      @close="editUserDialog = false"
      :user-item="user"
      :visible="editUserDialog"
      :role="role"
      @resetFilter="$emit('resetFilter', true)"
    />
  </tr>
</template>

<script>
import EditClientDialog from "../../components/users/EditClientDialog.vue";
import ConfirmDeleteDialog from "../../components/dialog/ConfirmDeleteDialog.vue";
import user from "../../mixins/user";
import notifications from "../../mixins/notifications";
import loader from "../../mixins/loader";
import EditUserDialog from "../../components/users/EditUserDialog.vue";
import SizeUi from "../../mixins/SizeUi";
import {mapActions, mapGetters} from 'vuex'
import StatusCircle from "@/components/move-list/StatusCircle.vue";
import * as getterTypes from "@/store/modules/pageLoadTime/types/getters";
import * as actionTypes from "@/store/modules/pageLoadTime/types/actions";

 export default {
  name: "UserRow",
  components: {
    EditClientDialog,
    ConfirmDeleteDialog,
    EditUserDialog,
    StatusCircle,
  },

  mixins: [user, notifications, loader, SizeUi],
  data: () => ({
    editUserDialog: false,
    deleteUserDialog: false,
    role: "",
    loadTime: 0
  }),
  props: {
    user: {
      required: true
    },
    index: {
      required: true
    },
    type: {
      required: false,
      default:'client'
    },
  },
  mounted() {
    const role = this.$route.name.replace("s", "");

    this.role = role;
    this.checkLoadTime();
  },
  methods: {
    ...mapActions('pageLoadTime', {
      setPageLoadTime: actionTypes.SET_PAGE_LOAD_TIME,
    }),
    checkLoadTime() {
      const endTime = performance.now();
      this.loadTime = endTime - this.startTime;
      this.setPageLoadTime(this.loadTime)
    },
    onEditUser(e) {
      this.editUserDialog = true;
      this.$emit("toggleRow", e);
    },
    onDeleteUser() {
      this.$emit("delete", this.user.uuid);
    },
  },
  computed: {
    ...mapGetters([
      "currentLanguage"
    ]),
    ...mapGetters('pageLoadTime', {
      startTime: getterTypes.GET_START_TIME,
    }),
    background() {
      if (this.index % 2 === 0) {
        return "#F8F8F8";
      }
      return "white";
    }
  }
};
</script>

<style scoped lang="scss">
.image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition: 0.4s ease;
  &.sm {
    width: 25px;
    height: 25px;
  }
}
</style>
