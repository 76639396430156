<template>
      <thead>
        <tr>
          <th class="icon text-center" v-if="canEdit"><VIcon dense> mdi-cogs</VIcon></th>
          <th class="icon text-center"><VIcon dense>mdi-account-box</VIcon></th>
          <th class="text-left md">
        <div class="header">
          <VTextField
            @keypress="validationForName($event)"
            @paste="validationForName($event)"
            :placeholder="$t('form.name')"
            dense
            hide-details
            solo
            v-model="filter.name"
            clearable
            :maxLength="25"
          />
        </div>
      </th>
      <th class="text-left md">
        <div class="header">
          <VTextField
            @keypress="validationForSurname($event)"
            @paste="validationForSurname($event)"
            :placeholder="$t('form.surname')"
            dense
            hide-details
            solo
            v-model="filter.surname"
            clearable
            :maxLength="25"
          />
        </div>
      </th>
      <th class="text-left xl">
        <div class="header d-block">
          <VTextField
            @keypress="validationForEmail($event)"
            @paste="validationForEmail($event)"
            :placeholder="$t('form.email')"
            dense
            hide-details
            solo
            v-model="filter.email"
            clearable
            :maxLength="50"
          />
        </div>
      </th>
      <th class="text-left sm">
        <div class="header">
          <VTextField
            @keypress="validationForPhoneNumber($event)"
            @paste="validationForPhoneNumber($event)"
            :placeholder="$t('table.phone')"
            dense
            hide-details
            solo
            v-model="filter.phone"
            :maxLength="13"
            clearable
          />
        </div>
      </th>
      <th v-if="$route.name === 'clients'" class="text-left sm">
        <div class="header" style="min-width: 75px">
          <VTextField
            @keypress="validationForNumbers($event)"
            @paste="validationForNumbers($event)"
            :placeholder="$t('form.balance')"
            dense
            hide-details
            solo
            v-model="filter.deliveries_balance"
            clearable
            :maxLength="5"
          />
        </div>
      </th>
      <th v-if="$route.name === 'clients'" class="text-left sm">
        <div class="header" style="min-width: 75px">
          <VTextField
            v-on:keypress="validationForNumbers($event)"
            @paste="validationForNumbers($event)"
            :placeholder="$t('form.limit')"
            dense
            hide-details
            solo
            v-model="filter.deliveries_negative_limit"
            clearable
            :maxLength="5"
          />
        </div>
      </th>
      <th class="text-left lg">
        <div class="header">
          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            :nudge-right="-60"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <VTextField
                v-model="filter.time_created"
                :placeholder="$t('table.register_data')"
                readonly
                append-icon="mdi-calendar"
                dense
                solo
                hide-details
                v-bind="attrs"
                v-on="on"
                clearable
              />
            </template>
            <v-date-picker
              :locale="currentLanguage.code"
              no-title
              v-model="filter.time_created"
              @input="dateMenu = false"
              :max="NextYear"
              min="2021"
            ></v-date-picker>
          </v-menu>
        </div>
      </th>
      <th class="text-left md">
        <div class="header" style="min-width: 95px">
          <v-select
            clearable
            :menu-props="{ bottom: true, offsetY: true }"
            :placeholder="$t('table.status')"
            :items="states"
            :item-value="'id'"
            dense
            hide-details
            solo
            v-model="filter.id_state"
          >
            <template v-slot:selection="{ item }">
              <div class="d-flex align-center justify-content-start">
                <StatusCircle :status-name="item.id" :type="'supply'" class="pb-1" />
                <div class="elispsis">
                  {{ $t(item.name) }}
                </div>
              </div>
            </template>
              <template v-slot:item="{ item }">
                <div class="d-flex align-center justify-content-start">
                    <StatusCircle
                      :status-name="item.id"
                      :type="'supply'"
                      class="pb-1"
                    />
                    <div class="elispsis">
                      {{ $t(item.name) }}
                    </div>
                </div>
              </template>
              </v-select>
            </div>
          </th>
        </tr>
      </thead>
</template>

<script>
import _ from "lodash";
import user from "../../mixins/user";
import {
  validationForEmail, validationForName,
  validationForNumbers,
  validationForPhoneNumber,
  validationForSurname
} from "../../mixins/helpers";
import {mapGetters} from 'vuex'
import StatusCircle from "@/components/move-list/StatusCircle.vue";

export default {
  components: {
    StatusCircle,
  },
  mixins:[user],
  name: "UsersHeader",
  data: () => ({
    dateMenu: false,
    filter: {
      name: "",
      surname: "",
      email: "",
      phone: "",
      time_created: "",
      deliveries_balance: "",
      deliveries_negative_limit: ""
    },
    states: [{
        id: "200",
        name: "users.state_200"
      },
      {
        id: "210",
        name: "users.state_210"
      },
      {
        id: "250",
        name: "users.state_250"
      }],
  }),
  computed: {
     ...mapGetters(["currentLanguage"]),
    NextYear() {
      const event = new Date().toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }).substr(0, 10);

      const [month, day, year] = event.split(/[\s,\/]+/);
      const formattedEvent = `${year}-${month}-${day}`;
      return formattedEvent;
    }
  },
  watch: {
    isLoading:{
      deep:true,
      handler(e){

      }
    },
    filter: {
      deep: true,
      handler(e) {
        const resultProps = {
          uselike: true
        };
        Object.keys(this.filter).forEach(property => {
          if (this.filter[property]) resultProps[property] = this.filter[property];
        });
        if(resultProps.phone){
          resultProps.phone = resultProps.phone.replace(/[^+\d]/g, '')
        }
        this.onSearch(resultProps);
     }
    },
  },

  methods: {
    validationForName,
    validationForSurname,
    validationForEmail,
    validationForPhoneNumber,
    validationForNumbers,
    onSearch: _.debounce(function(resultProps) {
      this.$emit("filter", resultProps);
    }, 500)
  },

  props: ["reset"]
};
</script>

<style scoped lang="scss">
.pagination-items {
  &-header {
    border-bottom: 1px solid #f2f2f2;
    background: transparent;

    .td {
      padding: 4px;
      text-align: center;
      input {
        border: 1px solid #eeeded;
        padding: 4px;
        &:disabled {
          background-color: #eeeded;
        }
      }
      span {
        font-family: Roboto;
        margin-bottom: 15px;
        display: block;
        color: #676767;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
      }
      &.date {
        input,
        select {
          border: unset;
        }
      }
    }
  }
}
</style>
