<template>
  <VDialog
    content-class="cross__dialog"
    v-model="visibility"
    max-width="500px"
    @click:outside="showConfirmDialog"
    :persistent="changed"
    scrollable
    :transition="$dialogTransition"
  >
    <VCard>
      <div class="cross__inner">
        <VBtn fab small elevation="0" @click="showConfirmDialog">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </div>
      <VCardTitle>
        <VRow v-if="!$vuetify.breakpoint.smAndDown" no-gutters justify="space-between">
        <span class="font-20" :class="{ 'text-body-1': $vuetify.breakpoint.xs }">{{
          `${$t("form.edit")} ${$t("users.client")}`
        }}</span>
        </VRow>
        <span v-if="$vuetify.breakpoint.smAndDown" class="font-20" :class="{ 'text-body-1': $vuetify.breakpoint.xs }">{{
          `${$t("form.edit")} ${$t("users.client")}`
        }}</span>
        <VSlideXReverseTransition>
          <VMenu
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <VBtn
                class="cursor-pointer"
                icon
                v-on="on"
                v-bind="attrs"
                small
              >
                <VIcon color="grey lighten-1" dense>
                  mdi-dots-vertical
                </VIcon>
              </VBtn>
            </template>
            <VList class="pa-0" dense>
              <VListItem
                :disabled="customer.is_login"
                @click="unloginClient"
                link
                class="pa-0"
              >
                <VRow no-gutters>
                  <VBtn  :disabled="customer.is_login" text>
                    {{ $t("users.disconnect_user") }}
                  </VBtn>
                </VRow>
              </VListItem>
              <VListItem class="pa-0" v-if="canBlock && customer.id_state == '210' " @click="blockUser(true)" link>
                <VRow no-gutters>
                  <VBtn color="error" text>{{ $t("users.block_user") }}</VBtn>
                </VRow>
              </VListItem>
              <VListItem class="pa-0" v-if="canBlock && customer.id_state == '250' " @click="blockUser(false)" link>
                <VRow no-gutters>
                  <VBtn color="success" text>{{ $t("users.unblock_user") }}</VBtn>
                </VRow>
              </VListItem>
              <VListItem class="pa-0" @click="activateDialog = true" v-if="canActivate && customer.id_state == '200'" link>
                <VRow no-gutters>
                  <VBtn color="success" text>{{ $t("users.activate_user") }}</VBtn
                  >
                </VRow>
              </VListItem>
            </VList>
          </VMenu>
        </VSlideXReverseTransition>
      </VCardTitle>
      <VDivider class="mb-3" />
      <VCardText>
        <VContainer class="py-0">
          <VRow
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'align-center mt-3 justify-center'
                : 'align-start mt-3 justify-start'
            "
          >
            <VCol cols="12" :class="$vuetify.breakpoint.smAndDown ? 'text-center' : 'flex'">
              <VRow v-if="!photoPreview && !userItem.path_img && !$vuetify.breakpoint.smAndDown">
                <VCol cols="3">
                  <img src="@/assets/images/account-photo.svg" alt="account-photo.svg" />
                </VCol>
                <VCol cols="6" class="align-self-center">
                  <label for="customer_photo">
                    <VBtn
                      :style="
                        `background: ${$vuetify.theme.dark ? '#545252' : '#fafafa'} border: ${
                          $vuetify.theme.dark ? '#fafafa' : '#1b1e21 1px solid'
                        }`
                      "
                      @click="getFile"
                      >{{ $t("profile.changeBtn") }}</VBtn
                    >
                  </label>
                </VCol>
              </VRow>
              <VRow v-if="!photoPreview && !userItem.path_img && $vuetify.breakpoint.smAndDown">
                <VCol cols="12">
                  <img src="@/assets/images/account-photo.svg" alt="account-photo.svg" />
                </VCol>
                <VCol cols="12" class="align-self-center">
                  <label for="customer_photo">
                    <VBtn
                      :style="
                        `background: ${$vuetify.theme.dark ? '#545252' : '#fafafa'} border: ${
                          $vuetify.theme.dark ? '#fafafa' : '#1b1e21 1px solid'
                        }`
                      "
                      @click="getFile"
                      >{{ $t("profile.changeBtn") }}</VBtn
                    >
                  </label>
                </VCol>
              </VRow>

              <VRow v-if="userItem.path_img && !photoPreview && !$vuetify.breakpoint.smAndDown">
                <VCol cols="3">
                  <img
                    :src="userItem.path_img"
                    alt=""
                    width="80"
                    class="img-preview"
                  />
                </VCol>
                <VCol cols="6" class="align-self-center">
                  <label for="customer_photo">
                    <VBtn
                      :style="
                        `background: ${$vuetify.theme.dark ? '#545252' : '#fafafa'} border: ${
                          $vuetify.theme.dark ? '#fafafa' : '#1b1e21 1px solid'
                        }`
                      "
                      @click="getFile"
                      >{{ $t("profile.changeBtn") }}</VBtn
                    >
                  </label>
                </VCol>
              </VRow>
              <VRow v-if="userItem.path_img && !photoPreview && $vuetify.breakpoint.smAndDown">
                <VCol cols="12">
                  <img
                    :src="userItem.path_img"
                    alt=""
                    width="80"
                    class="img-preview"
                  />
                </VCol>
                <VCol cols="12" class="align-self-center">
                  <label for="customer_photo">
                    <VBtn
                      :style="
                        `background: ${$vuetify.theme.dark ? '#545252' : '#fafafa'} border: ${
                          $vuetify.theme.dark ? '#fafafa' : '#1b1e21 1px solid'
                        }`
                      "
                      @click="getFile"
                      >{{ $t("profile.changeBtn") }}</VBtn
                    >
                  </label>
                </VCol>
              </VRow>
              <VFileInput
                id="customer_photo"
                class="d-none"
                v-model="customer.photo"
                accept="image/*"
                @change="makePreview"
              />
              <VRow v-if="photoPreview && !$vuetify.breakpoint.smAndDown" align="center">
                <VCol cols="3">
                  <img
                    :src="photoPreview"
                    alt=""
                    width="80"
                    class="img-preview"
                  />
                </VCol>
                <VCol cols="6" class="align-self-center">
                  <VBtn
                    @click="onChangeImage"
                    :style="
                      `background: ${$vuetify.theme.dark ? '#545252' : '#fafafa'} border: ${
                        $vuetify.theme.dark ? '#fafafa' : '#1b1e21 1px solid'
                      }`
                    "
                  >
                    {{ $t("btn.delete") }}
                  </VBtn>
                </VCol>
              </VRow>
              <VRow v-if="photoPreview && $vuetify.breakpoint.smAndDown" align="center">
                <VCol cols="12">
                  <img
                    :src="photoPreview"
                    alt=""
                    width="80"
                    class="img-preview"
                  />
                </VCol>
                <VCol cols="12" class="align-self-center">
                  <VBtn
                    @click="onChangeImage"
                    :style="
                      `background: ${$vuetify.theme.dark ? '#545252' : '#fafafa'} border: ${
                        $vuetify.theme.dark ? '#fafafa' : '#1b1e21 1px solid'
                      }`
                    "
                  >
                    {{ $t("btn.delete") }}
                  </VBtn>
                </VCol>
              </VRow>
            </VCol>
            <VCol cols="12" md="8" class="mb-2 text-center"> </VCol>
            <VRow class="align-start mt-3 justify-start">
              <VCol cols="12" class="py-0" v-if="accessRole">
                <VTextField
                  autocomplete="off"
                  :label="$t('form.company')"
                  dense
                  outlined
                  v-model="customer.company"
                  color="green"
                />
              </VCol>
              <VCol cols="12" class="py-0" v-if="!accessRole">
                <VTextField
                  autocomplete="off"
                  :label="$t('form.company')"
                  dense
                  outlined
                  v-model="customer.company"
                  color="green"
                />
              </VCol>
              <VCol cols="12" class="py-0" v-if="accessRole">
                <v-autocomplete
                  :items="depots"
                  :item-value="'uuid'"
                  :item-text="'name'"
                  outlined
                  :label="$t('form.storage')"
                  :no-data-text="language.NO_DATA_IS_AVAILABLE"
                  dense
                  v-model="customer.uuid_deport"
                  :error-messages="uuidErrors"
                  :disabled="!permissions.can_delete_manager"
                  color="success"
                  item-color="success"
                  v-on:keypress="validationForWarehouses($event)"
                  @paste="validationForWarehouses($event)"
                />
              </VCol>
              <VCol :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" class="py-0">
                <VTextField
                  :label="$t('form.name')"
                  dense
                  color="green"
                  outlined
                  v-model="customer.name"
                  :error-messages="nameErrors"
                  :hint="onEngText ? '' : $t('form.hint.NameNoEngText')"
                  v-on:keypress="validationForName($event)"
                  @paste="validationForName($event)"
                />
              </VCol>
              <VCol :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" class="py-0">
                <VTextField
                  :label="$t('form.surname')"
                  dense
                  outlined
                  color="green"
                  v-model="customer.surname"
                  :error-messages="surnameErrors"
                  :hint="onEngText ? '' : $t('form.hint.SurnameNoEngText')"
                  v-on:keypress="validationForSurname($event)"
                  @paste="validationForSurname($event)"
                />
              </VCol>
              <VCol :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" class="py-0">
                <VTextField
                  class="inputPhone"
                  :label="$t('form.phone_number')"
                  dense
                  color="green"
                  outlined
                  v-model="customer.phone"
                  :error-messages="phoneErrors"
                  prefix="+38"
                  :maxLength="20"
                  v-on:keypress="validationForPhoneNumber($event)"
                  @paste="validationForPhoneNumber($event)"
                >
                </VTextField>
              </VCol>
              <VCol :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" class="py-0">
                <VTextField
                  :label="$t('form.email')"
                  dense
                  outlined
                  v-model="customer.email"
                  autocomplete="off"
                  :error-messages="emailErrors"
                  color="green"
                  v-on:keypress="validationForEmail($event)"
                  @paste="validationForEmail($event)"
                />
              </VCol>
            </VRow>
            <VRow class="align-start mt-3 justify-start">
              <VCol
                cols="12"
                class="d-block font-14 text-left font-weight-medium"
                :style="`color: ${$vuetify.theme.dark ? '#fafafa' : '#545252'}`"
              >
                {{ $t('form.tariff_data') }}
              </VCol>
              <VCol
                :cols="$vuetify.breakpoint.smAndDown ? 12 : 6"
                class="py-0"
                v-if="
                  permissions.can_view_plans_list &&
                    type !== 'crm'
                "
              >
                <v-autocomplete
                  :label="$t('form.payment_plan')"
                  dense
                  outlined
                  v-model="customer.uuid_user_plan"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="success"
                  item-color="success"
                  :items="Object.values(paymentPlansList)"
                  :item-text="'name'"
                  :item-value="'uuid'"
                >
                  <template #item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.name }}
                      <VIcon class="ml-2" v-if="item.is_hidden == '1'"> mdi-eye-off </VIcon>
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                </v-autocomplete>
              </VCol>
              <VCol cols="12" class="py-0">
                <VMenu
                  ref="datePikerDialog"
                  v-model="datePikerDialog"
                  :close-on-content-click="false"
                  :return-value.sync="customer.date_crm_end"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <VTextField
                      v-model="customer.date_crm_end"
                      :label="$t('form.crm_end_date')"
                      readonly
                      dense
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      color="green"
                    ></VTextField>
                  </template>
                  <VDatePicker
                    v-model="customer.date_crm_end"
                    no-title
                    scrollable
                    :locale="currentLanguage.code"
                    :min="new Date().toISOString().substr(0, 10)"
                  >
                    <VSpacer></VSpacer>
                    <VBtn text color="primary" @click="datePikerDialog = false">
                      Cancel
                    </VBtn>
                    <VBtn
                      text
                      color="primary"
                      @click="$refs.datePikerDialog.save(customer.date_crm_end)"
                    >
                      OK
                    </VBtn>
                  </VDatePicker>
                </VMenu>
              </VCol>
              <VCol
                :cols="$vuetify.breakpoint.smAndDown ? 12 : 6"
                class="py-0"
                v-if="$route.name === 'clients'"
                style="position: relative;"
              >
                <VTextField
                  autocomplete="off"
                  :label="$t('form.deliveries_balance')"
                  dense
                  outlined
                  v-model="customer.deliveries_balance"
                  color="green"
                  :maxLength="10"
                  v-on:keypress="validationForNumbers($event)"
                  @paste="validationForNumbers($event)"
                />
                <VBtn icon small style="position: absolute;top: 20%;transform: translateY(-20%);right: 20px;cursor:pointer;" @click="editClientBalanceDialog = !editClientBalanceDialog">
                  <VIcon
                    size="22"
                    >mdi-plus</VIcon
                ></VBtn>
                <VDialog
                :transition="$dialogTransition"
                  max-width="450px"
                  v-model="editClientBalanceDialog"
                  content-class="cross__dialog"
                >
                  <VCard max-width="450">
                    <VRow class="px-4 pt-4" justify="center">
                      <VCol class="py-0">
                      <VTextField
                        color="success"
                        :maxLength="5"
                        :label="$t('form.add_to_balance')"
                        dense
                        outlined
                        v-model="editClientBalanceNumber"
                        v-on:keypress="validationForNumbers($event)"
                        @paste="validationForNumbers($event)"
                      />
                      </VCol>
                    </VRow>
                    <VRow class="px-4 py-2 mt-0" justify="center">
                      <VCol cols="6" class="pt-0">
                        <VBtn
                          @click="editClientBalanceDialog = false;editClientBalanceNumber = '';"
                          class="w-100 br-10 text-transform-none"
                          block
                          dark
                          :class="{ 'text-caption': $vuetify.breakpoint.xs }"
                        >
                          {{ $t("btn.cancel") }}
                        </VBtn>
                      </VCol>
                      <VCol cols="6" class="pt-0">
                        <VBtn
                          class="text-white text-transform-none br-10 w-100"
                          block
                          :class="{
                            'success-bg': editClientBalanceNumber !== '',
                            'text-caption': $vuetify.breakpoint.xs
                          }"
                          @click="editClientBalanceChange()"
                          :disabled="editClientBalanceNumber === ''"
                        >
                          {{ $t("btn.add") }}
                        </VBtn>
                      </VCol>
                    </VRow>
                  </VCard>
                </VDialog>
              </VCol>
              <VCol :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" class="py-0" v-if="$route.name === 'clients'">
                <VTextField
                  autocomplete="off"
                  :label="$t('form.deliveries_negative_limit')"
                  dense
                  outlined
                  v-model="customer.deliveries_negative_limit"
                  color="green"
                  :maxLength="10"
                  v-on:keypress="validationForNumbers($event)"
                  @paste="validationForNumbers($event)"
                />
              </VCol>
              <VCol cols="12" v-if="$route.name === 'clients'">
                <VTextField
                  autocomplete="off"
                  :label="$t('form.balance')"
                  dense
                  outlined
                  v-model="customer.balance"
                  color="green"
                  :maxLength="10"
                  v-on:keypress="validationForNumbers($event)"
                  @paste="validationForNumbers($event)"
                />
              </VCol>
              <VCol cols="12" class="py-0" v-if="type !== 'crm'">
                <VTextField
                  outlined
                  label="NP API Key"
                  :disabled="true"
                  dense
                  v-model="customer.np_apikey"
                  color="green"
                >
                  <template slot="append" v-if="customer.np_apikey">
                    <VBtn icon class="mb-1 py-0">
                      <VIcon @click="copyToCLipboardNs" class="icon-btn mb-0 pl-0"
                      >mdi-content-copy</VIcon
                      >
                    </VBtn>
                  </template>
                </VTextField>
              </VCol>
              <VCol cols="12" class="py-0">
                <VTextField
                  label="NewSend API Key"
                  dense
                  :disabled="true"
                  outlined
                  v-model="customer.uuid_crm_apikey"
                  color="green"
                >
                  <template slot="append" v-if="customer.uuid_crm_apikey">
                    <VBtn icon class="mb-1 py-0">
                      <VIcon @click="copyToCLipboardCrm" class="icon-btn mb-0 py-0"
                        >mdi-content-copy</VIcon
                      >
                    </VBtn>
                  </template>
                </VTextField>
              </VCol>
            </VRow>
            <VRow class="align-start mt-3 justify-start">
              <VCol
                cols="12"
                class="d-block font-14 text-left font-weight-medium"
                :style="`color: ${$vuetify.theme.dark ? '#fafafa' : '#545252'}`"
              >
                {{ $t('form.additional_features') }}
              </VCol>
              <VRow>
                <VCol cols="10">
                  <VCol :style="`color: ${$vuetify.theme.dark ? '#fafafa' : '#545252'}; font-size: 12px`" class="pa-0 pl-4">
                    {{ $t("users.individual_choice") }}
                  </VCol>
                  <VCol style="font-size: 12px" class="pa-0 pl-4">
                    {{ $t("users.specific_nsCode")}}
                  </VCol>
                </VCol>
                <VCol class="d-flex align-center" cols="2">
                  <v-switch
                    v-if="$route.name === 'clients'"
                    :false-value="'0'"
                    :true-value="'1'"
                    inset
                    hide-details
                    color="success"
                    v-model="customer.is_ns_direct_send"
                  />
                </VCol>
              </VRow>
            </VRow>
          </VRow>
        </VContainer>
      </VCardText>
      <VCardActions>
        <VContainer>
          <VRow class="my-0 py-0">
            <VCol cols="12" class="my-0 py-0">
              <VBtn
                class="text-white text-transform-none br-10 w-100"
                :class="{ 'success-bg': changed, 'text-caption': $vuetify.breakpoint.xs }"
                block
                :disabled="!changed"
                @click="onUpdate"
              >
                {{ $t("btn.update") }}
              </VBtn>
            </VCol>
            <VCol cols="12" class="pb-0">
              <VBtn
                @click="visibility = false"
                block
                class="w-100 br-10 text-transform-none"
                dark
                :class="{ 'text-caption': $vuetify.breakpoint.xs }"
              >
                {{ $t("btn.cancel") }}
              </VBtn>
            </VCol>
          </VRow>
        </VContainer>
      </VCardActions>
    </VCard>
    <ConfirmLeftDialog
      :visible="visibleConfirm"
      @close="visibleConfirm = false"
      @delete="onClose"
    />

    <ConfirmDialog
      v-if="canBlock"
      :visible="blockDialog"
      @close="blockDialog = false"
      @onConfirm="onBlock"
      @onCancel="blockDialog = false"
      :typeAlert="blocked ? 'error' : 'success'"
    >
      <template v-slot:header>
        {{ blockTitle }}
      </template>
      <template v-slot:description>
        {{ blockDesc }}
      </template>
    </ConfirmDialog>
    <ConfirmDialog
      v-if="canActivate"
      :visible="activateDialog"
      @close="activateDialog = false"
      @onConfirm="onActivate"
      @onCancel="activateDialog = false"
      :typeAlert="'success'"
    >
      <template v-slot:header>
        {{ $t("confirmDialog.activate_user.title") }}
      </template>
      <template v-slot:description>
        {{ $t("confirmDialog.activate_user.desc") }}
      </template>
    </ConfirmDialog>
  </VDialog>
</template>

<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { email, required, requiredIf } from "vuelidate/lib/validators";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import language from "../../mixins/language";
import dialogMixin from "../../mixins/dialogMixin";
import notifications from "../../mixins/notifications";
import usersService from "../../services/request/users/usersService";
import EventBus from "../../events/EventBus";
import depotService from "../../services/request/depot/depotService";
import user from "../../mixins/user";
import phoneMask from "@/validators/phoneMask";
import paymentPlansService from "@/services/request/payment-plans/paymentPlansService";
import {
  validationForEmail,
  validationForName,
  validationForNumbers,
  validationForPhoneNumber,
  validationForSurname,
  validationForWarehouses
} from "../../mixins/helpers";
import { mapGetters } from "vuex";
import noEngTextValidator from "@/validators/noEngText";
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";
import {right} from "core-js/internals/array-reduce";
import fileSizeValidation from "@/validators/fileSizeAvatar";

export default {
  name: "EditClientDialog",
  mixins: [language, notifications, validationMixin, dialogMixin, user],
  validations: {
    customer: {
      name: { required, noEngTextValidator },
      uuid_deport: {
        // eslint-disable-next-line func-names
        required: requiredIf(function() {
          return this.accessRole;
        })
      },
      surname: { required, noEngTextValidator },
      phone: { phoneMask, required },
      email: { email, required },
      photo: { fileSizeValidation }
    }
  },
  components: {
    ConfirmLeftDialog,
    ConfirmDialog
  },
  props: {
    userItem: {
      required: true
    },
    type: {
      required: false,
      default: "client"
    }
  },
  async mounted() {
    if (this.permissions.can_view_plans_list) {
      this.getPlansList();
    }
    this.updateRoleInfo();
    if(this.permissions.can_edit_client) {
      try {
        this.depots = await depotService.getDepotsList();
      } catch (e) {
        console.log(e)
      }
    }
    // orderDialog('this.userItem', this.userItem);
    this.customer = {
      uuid: this.userItem.uuid,
      name: this.userItem.name,
      company: this.userItem.company || "",
      surname: this.userItem.surname,
      email: this.userItem.email,
      id_state: this.userItem.id_state,
      is_login: this.userItem.is_login,
      uuid_deport: this.userItem.uuid_deport,
      phone: this.userItem.phone.replace("+38", ""),
      np_apikey: this.userItem.np_apikey,
      date_crm_end: this.userItem.date_crm_end || null,
      uuid_crm_apikey: this.userItem.uuid_crm_apikey,
      uuid_user_plan: this.userItem.uuid_user_plan,
      deliveries_negative_limit: this.userItem.deliveries_negative_limit,
      deliveries_balance: this.userItem.deliveries_balance,
      is_ns_direct_send: this.userItem.is_ns_direct_send,
      balance: this.userItem.balance

    };
    this.inputUser = {
      ...this.customer
    };
  },
  data: () => ({
    onEngText: true,
    customer: {
      uuid: "",
      name: "",
      surname: "",
      company: "",
      phone: "",
      email: "",
      uuid_deport: "",
      photo: null,
      date_crm_end: null,
      np_apikey: "",
      uuid_crm_apikey: "",
      uuid_user_plan: "",
      deliveries_negative_limit: "",
      deliveries_balance: "",
      is_ns_direct_send: "",
      balance: ""
    },
    visibleConfirm: false,
    depots: [],
    accessRole: false,
    photoPreview: null,
    loading: false,
    changed: false,
    datePikerDialog: false,
    paymentPlansList: {},
    blocked: false,
    blockTitle: null,
    blockDesc: null,
    blockDialog: false,
    activateDialog: false,
    editClientBalanceDialog: false,
    editClientBalance: false,
    editClientBalanceNumber: ""
  }),
  watch: {
    customer: {
      deep: true,
      handler() {
        this.changed = !_.isEqual(this.customer, this.inputUser);
        //
        //
      }
    }
  },
  methods: {
    validationForName,
    validationForSurname,
    validationForPhoneNumber,
    validationForEmail,
    validationForWarehouses,
    validationForNumbers,
    right,
    unloginClient(){
      this.customer.is_login = false;
      this.onUpdate()
    },
    async copyToCLipboardNs() {
      try {
        await navigator.clipboard.writeText(this.customer.np_apikey);
        this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
      } catch (e) {
        console.log(e)
      }
    },
    async copyToCLipboardCrm() {
      try {
        await navigator.clipboard.writeText(this.customer.uuid_crm_apikey);
        this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
      } catch (e) {
        console.log(e)
      }
    },
    async getPlansList() {
      try {
        const plansItem = await paymentPlansService.usersPlansList();
        this.paymentPlansList = plansItem;
        const notSetPlan = {
          name: this.$t("form.not_selected"),
          uuid: "00000000-0000-0000-0000-000000000000"
        };
        if(!this.paymentPlansList.length) {
          this.paymentPlansList.push(notSetPlan);
        }
      } catch (e) {
        console.log(e)
      }
    },
    showConfirmDialog() {
      if (this.changed) {
        this.visibleConfirm = true;
      } else {
        this.onClose();
      }
    },
    blockUser(check) {
      this.blocked = check;
      if (this.blocked) {
        this.blockTitle = this.$t("confirmDialog.block_user.title");
        this.blockDesc = this.$t("confirmDialog.block_user.desc");
      } else {
        this.blockTitle = this.$t("confirmDialog.unblock_user.title");
        this.blockDesc = this.$t("confirmDialog.unblock_user.desc");
      }
      this.blockDialog = true;
    },
    updateRoleInfo() {
      if (this.permissions.can_edit_client) {
        this.accessRole = true;
        // this.validations.user.deport_uuid.required = true;
      }
    },
    onClose() {
      this.$v.$reset();
      this.visibility = false;
    },
    onBlock() {
      if (this.blocked) {
        this.customer.id_state = "250";
      } else this.customer.id_state = "210";

      this.onUpdate();
    },
    async onUpdate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          const formData = new FormData();
          formData.append("uuid", this.customer.uuid);
          if (this.customer.photo) {
            formData.append("image", this.customer.photo);
          }
          if (this.customer.photo) {
            formData.append("is_ns_direct_send", this.customer.is_ns_direct_send);
          }
          // if (this.customer.uuid_crm_apikey) {
          //   formData.append('uuid_crm_apikey', this.customer.uuid_crm_apikey);
          // }
          const changedData = this.$getChangedData(this.customer, this.inputUser);
          changedData.forEach(data => {
            formData.append(data.field, data.value);
          });
          await usersService.updateUser(formData);
          this.setSuccessNotification(this.$t("users.user_update_success"));
          this.loading = false;
          this.$emit("resetFilter", true);
          EventBus.$emit("updated-user");
          this.visibility = false;
          this.$v.$reset();
        } catch (e) {
          this.loading = false;
          console.log(e)
        }
      }
    },
    async onActivate() {
      try {
        this.loading = true;

        await usersService.activateUser(this.customer.uuid);
        this.setSuccessNotification(this.$t("users.user_update_success"));
        this.loading = false;
        this.$emit("resetFilter", true);
        EventBus.$emit("updated-user");
        this.$v.$reset();
      } catch (e) {
        console.log(e)
      }
    },
    onResetImage() {
      this.photoPreview = false;
    },
    onChangeImage() {
      this.customer.photo = null;
      this.inputUser.photo = null;
      this.photoPreview = null;
    },
    getFile() {
      const fileUpload = document.getElementById("customer_photo");
      if (fileUpload != null) {
        fileUpload.click();
      }
    },
    makePreview() {
      if(this.$v.customer.photo.fileSizeValidation){
      let photoPreview = null;
      const { photo } = this.customer;
      const reader = new FileReader();
      reader.readAsDataURL(photo);
      reader.onloadend = ev => {
        photoPreview = ev.target.result;
        this.photoPreview = photoPreview;
      };
    } else{
        this.customer.photo = null;
        this.inputUser.photo = null;
        this.setErrorNotification(this.$t("form.errors.photoMaxSizeAvatar"));
    }
    },
    editClientBalanceChange() {
      this.customer.deliveries_balance =
        Number(this.customer.deliveries_balance) + Number(this.editClientBalanceNumber);
      this.editClientBalanceNumber = "";
      this.editClientBalanceDialog = false;
    }
  },
  computed: {
    ...mapGetters(["currentLanguage"]),
    uuidErrors() {
      const errors = [];
      if (!this.$v.customer.uuid_deport.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.customer.uuid_deport.required && errors.push(this.$t("form.errors.StorageRequired"));
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.customer.name.$dirty) {
        return errors;
      }
      if (!this.$v.customer.name.noEngTextValidator) {
        errors.push(this.$t("form.hint.NameNoEngText"));
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.customer.name.required && errors.push(this.$t("form.errors.NameRequired"));
      return errors;
    },
    surnameErrors() {
      const errors = [];
      if (!this.$v.customer.surname.$dirty) {
        return errors;
      }
      if (!this.$v.customer.surname.noEngTextValidator) {
        errors.push(this.$t("form.hint.SurnameNoEngText"));
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.customer.surname.required && errors.push(this.$t("form.errors.SurnameRequired"));
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.customer.phone.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.customer.phone.required && errors.push(this.$t("form.errors.PhoneNumberRequired"));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.customer.phone.phoneMask &&
        errors.push(this.$t("form.errors.PhoneNumberMustBeLike"));
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.customer.email.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.customer.email.required && errors.push(this.$t("form.errors.EmailRequired"));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.customer.email.email && errors.push(this.$t("form.errors.EmailMustBeValid"));
      return errors;
    }
  }
};
</script>

<style scoped>
.img-preview {
  border-radius: 15%;
  border: 1px solid lightgray;
  min-width: 80px;
  width: 80px;
  height: 80px;
  object-fit: cover;
}
</style>
