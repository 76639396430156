<template>
  <VCard class="px-2 py-2 mb-2">

    <div class="d-flex align-center justify-content-space-between" :class="{ 'justify-content-end': !permissions.can_create_admin ||  $route.name === 'admins' ||  $route.name === 'owners'}">
      <VCol cols="8" lg="3" md="7" v-if="($route.name === 'clients' || $route.name === 'employees' || $route.name === 'managers' || $route.name === 'crm-users') && permissions.can_create_admin" class="py-0">
        <v-autocomplete
          dense
          :items="Object.values(depots)"
          :item-value="'uuid'"
          :item-text="'name'"
          hide-details=""
          class="br-10"
          prepend-inner-icon="mdi-warehouse"
          solo
          v-model="chosenDeports"
          :no-data-text="language.NO_DATA_IS_AVAILABLE"
          :label="$t('form.storage')"
          @change="choseDeport(chosenDeports)"
          clearable
          color="success"
          item-color="success"
        />
      </VCol>
      <VCol cols="auto"  class="text-right px-0 py-0">
        <VBtn
          depressed
          class="success-bg text-white text-transform-none br-10"
          dark
          v-if="isUserDialog"
          @click="addUserDialog = true"
        >
          <VIcon :left="!$vuetify.breakpoint.mdAndDown">mdi-plus</VIcon>
          <div v-if="!$vuetify.breakpoint.mdAndDown">
          {{$t('form.add')}} {{ $t(`users.${role}`) }}
          </div>
        </VBtn>
        <VBtn
          v-if="($route.name === 'clients'|| $route.name === 'crm-users') && canAdd"
          depressed
          class="success-bg text-white text-transform-none br-10"
          dark
          @click="addClientDialog = true"
        >
          <VIcon :left="!$vuetify.breakpoint.mdAndDown">mdi-plus</VIcon>
          <div v-if="!$vuetify.breakpoint.mdAndDown">
           {{$t('form.add')}} {{ $t(`users.${role}`) }}
          </div>
        </VBtn>
      </VCol>
    </div>
    <VDivider v-if="isUserDialog || (($route.name === 'clients'|| $route.name === 'crm-users') && canAdd)" class="mt-3"/>
    <slot></slot>
    <AddUserDialog
      :addName="addName"
      v-if="canAdd"
      :visible="addUserDialog"
      @close="addUserDialog = false"
      :role="role"

    />
    <AddClientDialog :addName="addName" @resetFilter="$emit('resetFilter',true)" :visible="addClientDialog" @close="addClientDialog = false" v-if="addClientDialog"/>
    <div class="scroll-up__wrapper">
      <v-fab-transition>
        <VBtn
          v-if="isShowUpBtn"
          color="primary"
          small
          absolute
          bottom
          right
          fab
          @click="onScrollToTop"
          style="bottom: 0; right: 30px;"
        >
          <VIcon>mdi-chevron-up</VIcon>
        </VBtn>
      </v-fab-transition>
    </div>
  </VCard>
</template>

<script>
import depotService from '../../services/request/depot/depotService';
import EventBus from "@/events/EventBus";
import AddUserDialog from "../../components/users/AddUserDialog.vue";
import user from "../../mixins/user";
import language from "../../mixins/language";
import AddClientDialog from "../../components/users/AddClientDialog.vue";

export default {
  name: "UsersLayout",
  components: {
    AddUserDialog,
    AddClientDialog
  },
  mixins: [user, language],
  data: () => ({
    depots: [],
    chosenDeports: "",
    addUserDialog: false,
    role: "",
    addName: "",
    addClientDialog: false,
    isShowUpBtn: false,
  }),
  methods:{
    onScrollToTop() {
      this.$scrollTop({}, document.querySelector(".v-data-table__wrapper"));
    },
    choseDeport(event){
      this.$emit('chosenDeport',event)
    },
  },
  async mounted() {
    if(this.permissions.can_create_user_deport) {
      try {
        this.depots = await depotService.getDepotsList();
      } catch (e) {
        console.log(e)
      }
    }
    EventBus.$on("upBtn-modified", data => {
      this.isShowUpBtn = data;
    });
    const role = this.$route.name.replace("s", "");

    this.role = role;
    this.addName = this.$t(`users.${role}`);
  },
  computed: {
    isUserDialog(){
      return (this.canAdd && this.$route.name !== 'clients' && this.$route.name !== "crm-users")
    },
    canAdd() {
      if (this.$route.name === "clients") {
        return this.permissions.can_create_client;
      }
      if (this.$route.name === "employees") {
        return this.permissions.can_create_employee;
      }
      if (this.$route.name === "managers") {
        return this.permissions.can_create_manager;
      }
      if (this.$route.name === "admins") {
        return this.permissions.can_create_admin;
      }
      if (this.$route.name === "owners") {
        return this.permissions.can_create_owner;
      }
      if (this.$route.name === 'crm-users') {
        return this.permissions.can_see_crm_users_list;
      }
      return false;
    }
  }
};
</script>

<style lang="scss" >
.pagination-items {
  &-header,
  &-row {
    display: flex;
    > div {
      padding: 0 5px;
      &:nth-child(1) {
        width: 5%;
        display: flex;
        justify-content: center;
      }
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(6) {
        width: 20%;
      }

      &:nth-child(7) {
        padding: 0 10px;
        text-align: right;
        width: 10%;
      }
      &:nth-child(5) {
        width: 15%;
      }
    }
  }
}
</style>
